const availableCommands = [
  {
    name: "help",
    description: "Displays this help text",
  },
  {
    name: "about",
    description: "Displays information about me",
  },
  {
    name: "contact",
    description: "Displays contact information",
  },
  {
    name: "resume",
    description: "Displays my resume",
  },
];

function getHelpText() {
  let helpText = "Result:Available commands: \n";
  availableCommands.forEach((command) => {
    helpText += `Result:    ${command.name} - ${command.description} \n`;
  });
  return helpText;
}

export default function getCommandResult(cmd) {
  const command = cmd.split(" ")[0].toLowerCase();
  switch (command) {
    case "help":
      return getHelpText();
    case "about":
      return "Result:I am a software engineer with a passion for creating software that is intuitive and user-friendly.";
    case "contact":
      return "Result:You can contact me at: vikram.spidy@gmail.com";
    case "resume":
      return "";
    default:
      return "Result:Command not found. Type 'help' to see available commands";
  }
}
