import { useEffect, useLayoutEffect, useState } from "react";
import Cursor from "./cursor";
import "../css/cmdline.css";
import getCommandResult from "../utils/getCommandResult";

const cursorWidth = 10;
const cursorHeight = 20;

const startText = `
  |\\_/|        *******************************    (\\_/) \n
 / @ @ \\       *  "Vikram Kumar Dokkupalle"  *   (='.'=) \n
( > º < )      *     ♥ Made with React ♥     *   (")_(") \n
 '>>x<<'       *  (vikram.spidy@gmail.com)   *\n
 /  O  \\       *******************************\n
 =========================================================\n
 \n
`;

const commandStartText = "vikram-d.com/\u00A0";

export default function CommandLine() {
  const [text, setText] = useState(startText);
  const [command, setCommand] = useState("");
  const [commandHistory, setCommandHistory] = useState([]);
  const [commandHistoryIndex, setCommandHistoryIndex] = useState(0);

  useLayoutEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [text]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === "Backspace") {
        setCommand((val) => val.slice(0, -1));
      } else if (e.key === "Enter") {
        if (command.length > 0) {
          setText(
            (val) =>
              val +
              "\n" +
              commandStartText +
              command +
              "\n" +
              getCommandResult(command)
          );
          setCommandHistory([...commandHistory, command]);
        } else {
          setText((val) => val + "\n" + commandStartText + "\n");
        }
        setCommand("");
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (commandHistory.length > 0) {
          setCommandHistoryIndex((val) => {
            if (val === 0) {
              return commandHistory.length - 1;
            } else {
              return val - 1;
            }
          });
          setCommand(commandHistory[commandHistoryIndex]);
        }
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        if (commandHistory.length > 0) {
          setCommandHistoryIndex((val) => {
            if (val === commandHistory.length - 1) {
              return 0;
            } else {
              return val + 1;
            }
          });
          setCommand(commandHistory[commandHistoryIndex]);
        }
      } else {
        if (e.key.length === 1) {
          setCommand((val) => val + e.key);
        }
      }
    };

    window.addEventListener("keydown", keyDownHandler);
    return function () {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [command, commandHistory, commandHistoryIndex]);

  function renderText() {
    return text
      .split("\n")
      .map((line) => line.replace(/ /g, "\u00A0"))
      .map((line) => {
        console.log(line);
        if (line.startsWith("Result:")) {
          return <div className="result">{line.replace("Result:", "")}</div>;
        } else {
          return <div>{line}</div>;
        }
      });
  }

  return (
    <div className="command-line">
      {renderText()}
      <div className="command">
        <div className="command-start-text">{commandStartText}</div>
        {command}
        <Cursor width={cursorWidth} height={cursorHeight} />
      </div>
    </div>
  );
}
