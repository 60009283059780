import { useEffect, useState } from "react";

export default function Cursor({ xPos, yPos, width, height }) {
  const [hidden, setHidden] = useState(false);

  const style = {
    opacity: hidden ? 0 : 1,
    position: "relative",
    left: `${xPos}px`,
    top: `${yPos}px`,
    width: `${width}px`,
    height: `${height}px`,
    background: "green",
  };

  useEffect(() => {
    const id = setInterval(() => {
      setHidden((val) => !val);
    }, 500);
    return function () {
      clearInterval(id);
    };
  }, []);

  return <div style={style}></div>;
}
